.shortened-result {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* To position the message below */
    margin-bottom: 20px;
}

.shortened-url-input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1.1em;
    margin-right: 10px;
}

@media (max-width: 480px) {
    .shortened-url-input {
        width: 50%;
    }
}

.copy-btn {
    background: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.1s ease-in-out;
}

.copy-btn:hover {
    background: #218838;
}

.copy-btn.copied {
    background: #218838; /* A slightly different shade to indicate success */
    transform: scale(1.1); /* Subtle animation */
}

.copy-message {
    position: absolute;
    bottom: -25px;
    left: 5px;
    font-size: 0.9em;
    color: #28a745;
    animation: fadeInOut 3s;
}

/* Message fade-in and fade-out effect */
@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
}
