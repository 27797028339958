.radio-options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.radio-options label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    font-size: 1em;
    margin: 10px;
}

.radio-options input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ddd;
    border-radius: 90%;
    transition: all 0.3s ease;
}

.radio-options input[type="radio"]:checked ~ .radio-custom {
    background-color: #007bff;
}

@keyframes changeBorderRadius {
    0% {
        border-radius: 0%;
    }
    100% {
        border-radius: 50%;
    }
}


.radio-options input[type="radio"]:checked ~ .radio-custom::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 7.50px;
    width: 10px;
    height: 10px;
    background: white;
    transition: all 0.3s ease;
    animation: changeBorderRadius 0.4s ease forwards;
}

.algorithm-choice {
    margin-left: 10px;
    font-weight: bold;
}