.footer-container {
    background-color: #004494;
    color: #ffffff;
    padding: 50px 20px;
    text-align: center;
    font-size: 14px;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-brand h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.footer-brand p {
    font-size: 14px;
    margin: 0;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin: 5px 0;
}

.footer-links a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #ffd700;
}

.footer-social a {
    color: #ffffff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-social a:hover {
    color: #ffd700;
}

.footer-bottom {
    margin-top: 30px;
    font-size: 12px;
    opacity: 0.8;
    text-align: center;
}
