.shorten-btn {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em; /* Default font size */
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.4);
    /*margin-top: 30px;*/
    position: relative;
    overflow: hidden;
}

.shorten-btn:hover {
    background: linear-gradient(135deg, #0056b3, #004494);
    box-shadow: 0 6px 20px rgba(0, 86, 179, 0.5);
}

.shorten-btn:active {
    transform: scale(0.95);
    box-shadow: 0 3px 10px rgba(0, 86, 179, 0.5);
}

.shorten-btn.small {
    font-size: 1.0em;
    padding: 10px 20px;
}

.shorten-btn.medium {
    font-size: 1.2em;
    padding: 15px 30px;
}

.shorten-btn.large {
    font-size: 1.5em;
    padding: 20px 40px;
}

/* Gradient glow effect on hover */
.shorten-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
}

.shorten-btn:hover::before {
    transform: translateX(100%);
}
