.outer-how-it-works-container{
    background-color: rgba(255, 255, 255, 0.73);
    padding: 20px 50px;
}

.how-it-works-container {
    background-color: rgba(255, 255, 255, 0.1);
    /*color: #218838;*/
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 50px auto;
    text-align: center;
}

.how-it-works-title {
    font-size: 2em;
    color: #004494;
    margin-bottom: 30px;
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.step {
    background: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
    transform: translateY(-5px); /* Move up by 10px */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.step-number {
    background: #004494;
    color: #ffffff;
    font-size: 1.5em;
    font-weight: bold;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto 15px auto;
}

.step-description {
    font-size: 1em;
    color: #333333;
    margin: 0;
}
