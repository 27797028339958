.url-input {
    width: 90%;
    height: 20px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1em;
    border: 1px solid #007bff;
    border-radius: 8px;
    outline: none;

}

.url-input::placeholder {
    color: rgba(204, 204, 204, 0.83); /* Adjust this value for desired lightness */
}