.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Reduced gap for smaller screens */
    width: 100%;
}

.nav-links {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.nav-links li {
    font-size: 1em; /* Adjust font size for smaller screens */
    text-transform: uppercase;
    font-weight: 500;
}

.nav-links a, span {
    color: #010C80;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}

/* Hover and Disabled Styles */
.nav-links a:hover, span:hover {
    color: #ffcc00;
}

.navbar .disabled-link {
    pointer-events: none;
    color: gray;
    text-decoration: none;
    cursor: default;
    opacity: 0.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .nav-links {
        flex-direction: column; /* Stack links vertically */
        gap: 10px; /* Reduced gap for stacked layout */
    }

    .nav-links li {
        font-size: 0.9em; /* Further reduce font size */
    }
}
