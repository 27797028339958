.donate-button {
    position: fixed; /* Sticks the button to the viewport */
    bottom: 20px; /* Adjust distance from the bottom */
    right: 20px; /* Adjust distance from the right */
    background-color: #ffcc00; /* PayPal-themed yellow */
    color: #004494; /* Shortr theme color for text */
    border: none;
    border-radius: 50px; /* Rounded button */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for effect */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.donate-button:hover {
    background-color: #f5b800; /* Slightly darker yellow */
    transform: scale(1.05); /* Grow slightly on hover */
}
