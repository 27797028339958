.loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* The rotating circle */
.rotating-circle {
    width: 60px;
    height: 60px;
    border: 6px solid transparent; /* Transparent base */
    border-top: 6px solid #004494; /* Primary color (Shortr theme) */
    border-right: 6px solid #007bff; /* Secondary color for contrast */
    border-radius: 50%;
    animation: spin 0.7s cubic-bezier(0.3, 0.0, 0.2, 1) infinite; /* Smooth spin */
    position: relative;
}

/* The center shorts icon */
.center-icon {
    width: 30px; /* Adjust size as needed */
    height: 30px;
    background-image: url('../../../assets/icons/short-icon.png'); /* Path to your shorts icon */
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the icon */
}

/* Smooth spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
