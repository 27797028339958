.shorten-url-outer-container {
    background-color: #004494;
    padding: 100px;
}

/* Center the inner container and make it responsive */
.inner-shorten-url-container {
    background: #f8f9fa;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    width: 90%; /* Adjust to fit smaller screens */
}

.input-container {
     position: relative;
 }

/* Handle the width of custom inputs for larger screens */
.custom-width {
    width: 50% !important;
}

/* Responsive Design */
@media (max-width: 768px) {
    .shorten-url-outer-container {
        padding: 50px; /* Reduce padding for smaller screens */
    }

    .inner-shorten-url-container {
        padding: 20px; /* Reduce inner padding */
        max-width: 100%; /* Use full width for smaller screens */
    }

    .custom-width {
        width: 80% !important; /* Adjust input width for smaller screens */
    }

    h2 {
        font-size: 1.5em; /* Adjust heading size */
    }
}

@media (max-width: 480px) {
    .shorten-url-outer-container {
        padding: 20px; /* Further reduce padding for very small screens */
    }

    .inner-shorten-url-container {
        padding: 15px; /* Further reduce inner padding */
    }

    .custom-width {
        width: 50% !important; /* Full width for inputs on small screens */
    }

    h2 {
        font-size: 1.2em; /* Further adjust heading size */
    }
}
