.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #010C80;
    padding: 10px 20px; /* Reduced padding for smaller screens */
}

.header-left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.header-logo {
    height: 40px; /* Reduced size for smaller screens */
    width: auto;
    cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .header {
        flex-direction: column; /* Stack header items vertically */
        align-items: center;
    }

    .header-left,
    .header-right {
        flex: unset;
        width: 100%; /* Full width for smaller screens */
        text-align: center; /* Center content */
    }

    .header-logo {
        height: 30px; /* Further reduce size for very small screens */
    }
}
