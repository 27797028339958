.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.auth-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.auth-form h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    color: #333;
}

.input-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 0.9rem;
    color: #555;
    text-align: left;
    width: 100%; /* Match the full width of the container */
}

.input-group input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 93%; /* Stretch inputs to match container width */
}

.last-label {
    margin-bottom: 30px !important;
}

.auth-button {
    width: 90%;
}


.auth-options {
    margin-top: 1.5rem;
}

.toggle-link {
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.toggle-link:hover {
    text-decoration: underline;
}

.divider {
    margin: 1.5rem 0;
    position: relative;
    font-size: 0.8rem;
    color: #888;
}

.divider::before,
.divider::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background: #ddd;
}

.divider::before {
    left: 0;
}

.divider::after {
    right: 0;
}
